<template>
  <div>
    <el-row>
      <el-col>
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="$t('cip.plat.sys.visual3.title.indexHeadTitle')"
          @head-remove="headRemove"
          @head-add-tabs="headAddTabs"
        ></head-layout>
      </el-col>
    </el-row>

    <grid-head-layout
      ref="gridHeadLayout"
      :grid-head-btn="gridHeadBtn"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :data-total="page.total"
      :grid-row-btn="gridRowBtn"
      :page="page"
      :table-data="tableData"
      :table-loading="tableLoading"
      :table-options="tableOptions"
      @gird-handle-select-click="selectionChange"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @row-remove="rowRemove"
      @row-preview="preview"
      @row-role="rolePermission"
      @row-edit="addOrUpdateHandle"
    >
      <template #customBtn="{row}">
        <el-button
          type="text"
          size="small"
          @click="rowEnable(row)"
        >{{ row.enabledMark == 1 ? $t('cip.cmn.btn.enable') : $t('cip.cmn.btn.disable')}}
        </el-button>
        <el-button
          v-if="row.enabledMark == 2"
          type="text"
          size="small"
          @click="handleDefault(row)"
        >{{ row.isDefault == 1 ? '非默认' : '默认'}}
        </el-button>
      </template>
    </grid-layout>

    <el-dialog :visible.sync="dialogVisible" class="SNOWS-dialog SNOWS-dialog-add SNOWS-dialog_center"
               lock-scroll :title="$t('cip.plat.sys.visual3.field.addPortal')" width="600px">
      <div class="add-main">
        <div class="add-item add-item-left" @click="addOrUpdateHandle(1)">
          <i class="add-icon icon-ym icon-ym-customUrl"></i>
          <div class="add-txt">
            <p class="add-title">{{ $t('cip.plat.sys.visual3.field.customPath') }}</p>
            <p class="add-desc">{{ $t('cip.plat.sys.visual3.field.confCustomPath') }}</p>
          </div>
        </div>
        <div class="add-item" @click="addOrUpdateHandle(0)">
          <i class="add-icon icon-ym icon-ym-pageDesign"></i>
          <div class="add-txt">
            <p class="add-title">{{ $t('cip.plat.sys.visual3.field.page') }}</p>
            <p class="add-desc">{{ $t('cip.plat.sys.visual3.field.drag') }}</p>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('cip.plat.sys.visual3.title.allocationTitle')"
      :visible.sync="roleDialogVisible"
    >
      <avue-input-tree
        v-model="roleIds"
        size="mini"
        dataType="string"
        clearable
        multiple
        :placeholder="$t('cip.plat.workflow.components.userOption.field.role')"
        :dic="roleList"
        :props="roleProps || {label: 'roleName', value: 'id'}">
      </avue-input-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click="roleDialogVisible = false">{{ $t('cip.cmn.btn.celBtn') }}</el-button>
        <el-button type="primary" @click="roleHandle">{{ $t('cip.cmn.btn.defBtn') }}</el-button>
      </div>
    </el-dialog>
    <Form v-if="formVisible" ref="form" @close="closeForm"/>
    <Form1 v-if="form1Visible" ref="form1" @close="closeForm1"/>
    <Preview :id="activeId" :visible.sync="previewVisible"/>
  </div>
</template>

<script>
import Form from './Form'
import Form1 from './Form1'
import Preview from './IndexPreview'
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {getpage, getPortalType, remove, Submit, getRoles, roleHandle} from "@/api/onlineDev/portal";

export default {
  name: 'onlineDev-visualPortal',
  components: {
    Form, Form1, Preview, GridLayout,
    HeadLayout,
  },
  data() {
    return {
      portalId: '',
      roleDialogVisible: false,
      roleIds: "",
      userOption: {
        roleUrl: '/api/sinoma-system/search/role',
      },
      roleList: [],
      list: [],
      tableLoading: false,
      previewVisible: false,
      fullName: null,
      activeId: '',
      selectionList: [],
      category: null,
      page: {
        currentPage: 1,
        pageSize: 20,
        total: 0
      },
      dialogVisible: false,
      form1Visible: false,
      formVisible: false
    }
  },
  computed: {
    ...mapGetters(["permission"]),
    ids() {
      let ids = this.selectionList.map(item => item.id);
      return ids.join(",");
    },
    roleUrl() {
      return this.userOption.roleUrl
    },
    customOption() {
      return this.userOption.customOption
    },
    roleProps() {
      return this.customOption ? this.customOption.roleProps : null
    },
    searchColumns() {
      return [
        {
          prop: "fullName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t('cip.plat.sys.visual3.field.name'),
        },
        {
          prop: "category",
          span: 4,
          type: 'select',
          dataType: 'string',
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=portal_type",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: this.$t('cip.plat.sys.visual3.field.portal')
        },
      ]
    },
    tableOptions() {
      return {
        menuWidth: 330,
        height: 0,
        customAddBtn: true,
        column: [
          {
            label: this.$t('cip.plat.sys.visual3.field.name'),
            prop: 'fullName',
            width: 500,
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.sys.visual3.field.enCode'),
            prop: 'enCode',
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.sys.visual3.field.portal'),
            prop: 'category',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=portal_type",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            width: 130,
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.sys.visual3.field.sort'),
            prop: 'sortCode',
            width: 80,
            align: "right",
            overHidden: true,
          },
        ],
      }
    },
    gridRowBtn() {
      let result = [];
      //新增按钮
      if (this.permission.visualPortal_preview) {
        result.push({
          label: this.$t('cip.plat.sys.visual3.btn.previewBtn'),
          emit: "row-preview",
          type: "button",
          icon: ""
        });
      }
      result.push({
        label: this.$t('cip.cmn.btn.rolePermission'),
        emit: "row-role",
        type: "button",
        icon: ""
      });
      if (this.permission.visualPortal_edit) {
        result.push({
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "button",
          icon: ""
        });
      }
      if (this.permission.visualPortal_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
          icon: ""
        });
      }
      return result
    },
    gridHeadBtn() {
      return []
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      if (this.permission.visualPortal_add) {
        result.push({
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        });
      }
      if (this.permission.visualPortal_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        });
      }
      return result
    },
  },
  created() {
    this.getDictionaryData()
    this.initData()
  },
  mounted() {
    this.getRoleList()
    this.onLoad(this.page, {})
  },
  methods: {
    roleHandle() {
      roleHandle(this.portalId, this.roleIds).then(res => {
        this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'));
        this.roleDialogVisible = false
      })
    },
    getRoles(portalId) {
      getRoles(portalId).then(res => {
        this.roleIds = res.data.data
      })
    },
    rowEnable(row) {
      this.$loading();
      if (row.enabledMark == 1) {
        row.enabledMark = 2
      } else if (row.enabledMark == 2) {
        row.enabledMark = 1
      }
      Submit(row).then(res => {
        this.onLoad(this.page, {})
      })
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    selectionChange(list) {
      this.selectionList = list;
      console.log(this.selectionList)
    },
    headRemove() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          let ids = this.selectionList.map(item => {
            return item.id
          }).join(',')
          return remove(ids);
        })
        .then(() => {
          // 刷新表格数据并重载
          this.data = [];
          this.parentId = 0;
          this.$refs.gridLayOut.selectionClear();
          // 表格数据重载
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id).then(() => {
            this.$message({
              type: "success",
              message: this.$t('cip.cmn.msg.success.operateSuccess')
            });
            this.onLoad(this.page);
          });
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getpage(
        page.currentPage,
        page.pageSize,
        Object.assign(params)
      ).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        this.$loading().close();
      });
    },
    headAddTabs() {
      this.dialogVisible = true
    },
    reset() {
      this.keyword = ''
      this.category = ''
      this.search()
    },
    search() {
      this.listQuery = {
        currentPage: 1,
        pageSize: 20,
        sort: 'desc',
        sidx: ''
      }
      this.initData()
    },
    getDictionaryData() {
      getPortalType().then(res => {
        console.log(res)
        this.categoryList = res.data.data
      })
    },
    initData() {
      this.listLoading = true
      let query = {
        ...this.listQuery,
        fullName: this.fullName,
        category: this.category
      }
      this.onLoad(this.page, query);
      // getPortalList(query).then(res => {
      //   this.list = res.data.list
      //   this.total = res.data.pagination.total
      //   this.listLoading = false
      // })
    },
    handleDel(id) {
      this.$confirm(this.$t('common.delTip'), this.$t('common.tipTitle'), {
        type: 'warning'
      }).then(() => {
        Delete(id).then(res => {
          this.$message({
            type: 'success',
            message: res.msg,
            duration: 1000,
            onClose: () => {
              this.initData()
            }
          });
        })
      }).catch(() => {
      });
    },
    copy(id) {
      this.$confirm('您确定要复制该门户, 是否继续?', '提示', {
        type: 'warning'
      }).then(() => {
        Copy(id).then(res => {
          this.$message({
            type: 'success',
            message: res.msg,
            duration: 1000,
            onClose: () => {
              this.initData()
            }
          });
        })
      }).catch(() => {
      });
    },
    rolePermission(row) {
      this.roleIds = ''
      this.portalId = row.id
      this.getRoles(row.id)
      this.roleDialogVisible = true

    },
    preview(row) {
      if (!row) return
      this.activeId = row.id
      this.previewVisible = true
    },
    exportTemplate(id) {
      this.$confirm('您确定要导出该门户, 是否继续?', '提示', {
        type: 'warning'
      }).then(() => {
        exportTemplate(id).then(res => {
          this.snows.downloadFile(res.data.url)
        })
      }).catch(() => {
      });
    },
    distribute(id) {
      this.transferId = id
      this.transferShow = true
    },
    handleDefault(row){
      this.$loading();
      if (row.isDefault == 0) {
        row.isDefault = 1
      } else if (row.isDefault == 1) {
        row.isDefault = 0
      }
      Submit(row).then(res => {
        this.onLoad(this.page, {})
      }).catch(() => {
        row.isDefault = 0
        this.$loading().close();
      })
    },
    addOrUpdateHandle(type, id) {

      if (typeof type == 'object') {
        id = type.id
        type = type.type
      }

      this.dialogVisible = false
      const key = type === 1 ? 'form1' : 'form'
      this[key + 'Visible'] = true
      const time = type === 1 && !id ? 300 : 0

      setTimeout(() => {
        this[key + 'Visible'] = true
        this.$nextTick(() => {

          this.$refs[key].init(this.categoryList, id)
        })
      }, time);
    },
    closeForm(isRefresh) {
      this.formVisible = false
      if (isRefresh) this.initData()
    },
    closeForm1(isRefresh) {
      this.form1Visible = false
      if (isRefresh) this.initData()
    },
    getRoleList() {
      this.$axios.get(this.roleUrl).then(res => {
        this.roleList = res.data.data
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.add-txt p {
  margin: 0;
}
</style>
