<template>
  <el-aside class="right-box" width="300px">
    <div class="cap-wrapper">{{$t('cip.plat.sys.visual3.field.ComponentProperties')}}</div>
    <el-scrollbar class="aside-scrollbar">
      <el-form label-width="80px" labelPosition="left" size="small">
        <template v-if="activeData">
          <el-form-item v-if="activeData.title !== undefined" :label="$t('cip.plat.sys.visual3.field.title')">
            <el-input v-model="activeData.title" :placeholder="$t('cip.plat.sys.visual3.msg.titleWarning')" />
          </el-form-item>
          <template v-if="activeData.snowsKey === 'commonFunc'">
            <el-divider>{{$t('cip.plat.sys.visual3.field.option')}}</el-divider>
            <div v-for="(item, index) in activeData.list" :key="index" class="select-item">
             <SNOWStreeSelect :options="menuList" v-model="item.id"
                                :placeholder="$t('cip.plat.sys.visual3.msg.menuWarning')" lastLevel clearable
                                @change="getSelectValue(arguments,index)">
                            </SNOWStreeSelect>
              <div class="close-btn select-line-icon" @click="delSelectItem(item,item.id)">
                <i class="el-icon-remove-outline" />
              </div>
            </div>
            <div class="mt-10">
              <el-button v-if="activeData.list.length<10" icon="el-icon-plus"
                         @click="addSelectItem">{{$t('cip.plat.sys.visual3.field.AddOptions')}}
              </el-button>
            </div>
          </template>
          <template v-if="activeData.snowsKey === 'dataBoard'">
            <div v-for="(item, index) in activeData.list" :key="index" class="dataBoard-item">
              <p class="head"><span>{{$t('cip.plat.sys.visual3.field.option')}}{{ index + 1 }}</span>
                <a class="head-icon el-icon-close" @click="delDataBoardItem(index)"></a>
              </p>
              <el-form-item :label="$t('cip.plat.sys.visual3.field.title')">
                <el-input v-model="item.fullName" :placeholder="$t('cip.plat.sys.visual3.msg.titleWarning')" />
              </el-form-item>
              <el-form-item :label="$t('cip.plat.sys.visual3.field.dataType')">
                <el-radio-group v-model="item.dataType">
                  <el-radio-button label="static">{{$t('cip.plat.sys.visual3.field.StaticData')}}</el-radio-button>
                  <el-radio-button label="dynamic">{{$t('cip.plat.sys.visual3.field.RemoteData')}}</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="item.dataType==='dynamic'" :label="$t('cip.plat.sys.visual3.field.dataInterface')">
                <interface-dialog :title="item.propsName" :value="item.propsApi" :popupTitle="$t('cip.plat.sys.visual3.field.dataInterface')"
                                  @change="propsUrlChange(arguments , index)" />
              </el-form-item>
              <el-form-item v-if="item.dataType==='static'" :label="$t('cip.plat.sys.visual3.field.numericalValue')">
                <el-input v-model="item.num" :placeholder="$t('cip.plat.sys.visual3.msg.numericalWarning')" />
              </el-form-item>
              <el-form-item :label="$t('cip.plat.sys.visual3.field.Icon')">
                <el-input v-model="item.icon" :placeholder="$t('cip.plat.sys.visual3.msg.iconWarning')">
                  <el-button slot="append" @click="openIconsDialog(index)">
                    {{$t('cip.plat.sys.visual3.field.select')}}
                  </el-button>
                </el-input>
              </el-form-item>
              <el-divider></el-divider>
            </div>
            <el-button v-if="activeData.list.length<6" icon="el-icon-plus"
                       @click="addDataBoardItem">{{$t('cip.plat.sys.visual3.field.AddOptions')}}}
            </el-button>
          </template>
          <template v-if="activeData.snowsKey && activeData.snowsKey.indexOf('Chart')>-1">
            <el-form-item :label="$t('cip.plat.sys.visual3.field.dataType')">
              <el-radio-group v-model="activeData.dataType" size="small" @change="dataTypeChange">
                <el-radio-button label="static">{{$t('cip.plat.sys.visual3.field.StaticData')}}</el-radio-button>
                <el-radio-button label="dynamic">{{$t('cip.plat.sys.visual3.field.RemoteData')}}</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="activeData.dataType==='static'" :label="$t('cip.plat.sys.visual3.field.DataSettings')">
              <el-button @click="showData(activeData.option)">{{$t('cip.cmn.btn.viewBtn')}}</el-button>
            </el-form-item>
            <el-form-item v-if="activeData.dataType==='dynamic'" :label="$t('cip.plat.sys.visual3.field.dataInterface')">
              <interface-dialog :title="activeData.propsName" :value="activeData.propsApi"
                                :popupTitle="$t('cip.plat.sys.visual3.msg.InterfaceWarning')" @change="propsApiChange" />
            </el-form-item>
          </template>
        </template>
      </el-form>
    </el-scrollbar>
    <JSONArea v-if="areaVisible" ref="JSONArea" @refresh="updateOption" />
    <iconBox :current="current" :visible.sync="iconsVisible" @choiceIcon="setIcon" />
  </el-aside>
</template>
<script>
import draggable from 'vuedraggable'
import {getDataInterfaceSelector} from '@/api/systemData/dataInterface'
import iconBox from '@/components/SNOWS-iconBox'
import JSONArea from './JSONArea'
import InterfaceDialog from '@/components/Process/PropPanel/InterfaceDialog'
import SNOWStreeSelect from '@/components/SNOWS-treeSelect'
import {mapGetters} from "vuex";

export default {
  props: ['activeData'],
  components: {draggable, iconBox, JSONArea, InterfaceDialog,SNOWStreeSelect},
  data() {
    return {
      iconsVisible: false,
      areaVisible: false,
      currentIndex: 0,
      current: '',
      menuList: [],
      dataInterfaceOptions: [],
      changeObject: []
    }
  },
  computed: {
    ...mapGetters(['menu'])
  },
  created() {
    this.getMenuList()
    // this.getDataInterfaceSelector()
  },
  methods: {
    handleChange() {
      console.log(this.changeObject, '124')
    },
    getMenuList() {
      this.menuList = this.menu;
      this.menuList = JSON.parse(JSON.stringify(this.menuList).replace(/"name"/g, '"fullName"'));
      this.menuList = JSON.parse(JSON.stringify(this.menuList).replace(/"path"/g, '"urlAddress"'));
      this.menuList = JSON.parse(JSON.stringify(this.menuList).replace(/"source"/g, '"icon"'));
    },
    removeEmptyChildren (node) {
      node.forEach(item => {
        if ('children' in item && item.children.length === 0) {
          delete item.children
        } else if ('children' in item && item.children.length) {
          this.removeEmptyChildren(item.children)
        }
      })
      return node
    },
    getDataInterfaceSelector() {
      getDataInterfaceSelector().then(res => {
        this.dataInterfaceOptions = res.data
      })
    },
    getSelectValue(data, i) {
      console.log(data,'124')
      if (!data[0]) {
        this.$set(this.activeData.list, i, {
          fullName: '',
          id: '',
          urlAddress: '',
          icon: '',
          iconBackgroundColor: '',
          type: '',
          propertyJson: '',
          linkTarget: '_self',
          enCode: ''
        })
      } else {
        console.log(data, '127')
        let iconBackgroundColor = ''
        if (data[1].propertyJson) {
          let propertyJson = JSON.parse(data[1].propertyJson)
          iconBackgroundColor = propertyJson.iconBackgroundColor || ''
        }
        this.$set(this.activeData.list, i, {
          fullName: data[1].fullName,
          id: data[1].id,
          urlAddress: data[1].urlAddress,
          type: data[1].type,
          propertyJson: data[1].propertyJson,
          linkTarget: data[1].linkTarget,
          enCode: data[1].enCode,
          icon: data[1].icon,
          iconBackgroundColor: iconBackgroundColor
        })
      }
    },
    addSelectItem() {
      this.activeData.list.push({
        fullName: '',
        id: '',
        urlAddress: '',
        icon: '',
        iconBackgroundColor: '',
        type: '',
        propertyJson: '',
        linkTarget: '_self',
        enCode: ''
      })
    },
    delSelectItem(index) {
      if (this.activeData.list.length < 3) {
        this.$message({
          message: this.$t('cip.plat.sys.visual3.msg.continueWarning'),
          type: 'warning'
        });
        return
      }
      this.activeData.list.splice(index, 1)
    },
    addDataBoardItem() {
      this.activeData.list.push({fullName: "", num: '', dataType: 'static', propsApi: '', icon: ""})
    },
    delDataBoardItem(index) {
      if (this.activeData.list.length < 3) {
        this.$message({
          message: this.$t('cip.plat.sys.visual3.msg.continueWarning'),
          type: 'warning'
        });
        return
      }
      this.activeData.list.splice(index, 1)
    },
    openIconsDialog(index) {
      this.iconsVisible = true
      this.currentIndex = index
      this.current = this.activeData.list[this.currentIndex].icon
    },
    setIcon(val) {
      this.activeData.list[this.currentIndex].icon = val
    },
    showData(option) {
      this.areaVisible = true
      this.$nextTick(() => {
        this.$refs.JSONArea.init(option)
      })
    },
    updateOption(data) {
      let option = data ? JSON.parse(data) : {}
      this.activeData.option = option
    },
    dataTypeChange() {
      this.activeData.propsApi = ''
      this.activeData.propsName = ''
    },
    propsUrlChange(data, index) {
      if (!data || !data.length) {
        this.activeData.list[index].propsApi = ''
        this.activeData.list[index].propsName = ''
        return
      }
      this.activeData.list[index].propsApi = data[0]
      this.activeData.list[index].propsName = data[1].fullName
    },
    propsApiChange(val, item) {
      if (!val) {
        this.activeData.propsApi = ''
        this.activeData.propsName = ''
        return
      }
      this.activeData.propsApi = val
      this.activeData.propsName = item.fullName
    }
  }
}
</script>
<style lang="scss" scoped>
.right-box {
  background: #fff;
  border-radius: 4px;

  .cap-wrapper {
    text-align: center;
  }

  ::v-deep .el-scrollbar__view {
    padding: 15px;
  }

  .select-item {
    display: flex;
    border: 1px dashed #fff;
    box-sizing: border-box;

    & .close-btn {
      cursor: pointer;
      color: #f56c6c;
    }

    .el-select {
      flex: 1;
    }
  }

  .select-line-icon {
    line-height: 32px;
    font-size: 22px;
    padding: 0 4px;
    color: #606266;
  }

  .dataBoard-item {
    .el-form-item--small.el-form-item {
      margin-bottom: 10px;
    }

    .el-divider--horizontal {
      margin: 14px 0;
    }

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .head-icon {
        color: #f56c6c;
      }
    }
  }
}
</style>
