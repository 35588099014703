<template>
  <el-dialog :title="$t('cip.plat.sys.visual3.title.dataSettingsTitle')" :close-on-click-modal="false" :visible.sync="visible"
    class="SNOWS-dialog SNOWS-dialog_center" lock-scroll width="600px" append-to-body>
    <JSONEditor v-model="responseData" mode="json" class="preview" />
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">{{$t('cip.cmn.btn.celBtn')}}</el-button>
      <el-button type="primary" @click="closeDialog()">{{$t('cip.cmn.btn.defBtn')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import JSONEditor from '@/components/JsonEditor'

export default {
  components: { JSONEditor },
  data() {
    return {
      visible: false,
      responseData: ''
    }
  },
  methods: {
    init(option) {
      this.visible = true
      this.responseData = JSON.stringify(option, null, 2)
    },
    closeDialog() {
      this.visible = false
      this.$emit('refresh', this.responseData)
    },
  }
}
</script>
<style lang="scss" scoped>
.SNOWS-dialog_center {
  ::v-deep .el-dialog__body {
    padding-bottom: 20px !important;
  }
}
::v-deep .preview {
  border: 1px solid #dcdfe6;
}
::v-deep .json-editor {
  height: 430px;
  overflow: auto;
}
</style>
